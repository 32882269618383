<template>
  <router-view
    v-if="game"
    :game="game"
    :player-id="currentPlayerId"
  />
</template>

<script>
import _throttle from 'lodash/throttle'
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import { mapActions, mapGetters } from 'vuex'

import AVAILABLE_STATUS from '../availableStatus'

export default {
  name: 'MultiTaskContainer',
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    game: null,
  }),
  computed: {
    ...mapGetters('me', {
      trainingSessionPlayerId: 'trainingSessionPlayerId',
    }),
    ...mapGetters('session', {
      gameStatusListByResourceId: 'gameStatusListByResourceId',
      gameNameListByResourceId: 'gameNameListByResourceId',
    }),
    gameStatus () {
      return this.gameStatusListByResourceId[this.gameId]
    },
    gameName () {
      return this.gameNameListByResourceId[this.gameId]
    },
    currentPlayer () {
      return this.game?.playerList
        ?.find(player => player.trainingSessionPlayerId === this.trainingSessionPlayerId)
    },
    currentPlayerId () {
      return this.currentPlayer?.id
    },
    currentPlayerStatus () {
      return this.currentPlayer?.status
    },
    expectedRouteName () {
      return this.$options.expectedRoutesForStatus[this.currentPlayerStatus]
    },
    isExpectedRouteName () {
      return this.$route.name === this.expectedRouteName
    },
  },
  sockets: {
    multiTaskSessionPlayerReady ({ multiTaskSessionPlayerId }) {
      this.updatePlayerStatus({
        id: multiTaskSessionPlayerId,
        status: AVAILABLE_STATUS.ready,
      })
    },
  },
  watch: {
    expectedRouteName () {
      this.ensureRouteIsValid()
    },
    gameStatus () {
      if (this.gameStatus !== 'opened') {
        this.$router.go(-1)
      }
    },
    gameName: {
      immediate: true,
      handler () {
        this.setTitle(this.$t(`home.game.title.${this.gameName}`))
      },
    },
  },
  created () {
    this.fetchData()
  },
  methods: {
    ...mapActions('ui', {
      setTitle: 'setTitle',
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    fetchData: _throttle(async function fetchData () {
      this.game = {
        ...await Repositories.multitask.get(this.gameId),
        name: this.gameName,
        type: this.gameName === 'multiTaskSequential' ? 'sequential' : 'parallel',
      }
    }, 200, { leading: true, trailing: true }),
    ensureRouteIsValid () {
      if (this.expectedRouteName && !this.isExpectedRouteName) {
        this.$router.replace({ name: this.expectedRouteName })
      }
    },
    updatePlayerStatus ({ id, ...updatedFields }) {
      this.game.playerList = Helpers.patchItem({
        collection: this.game.playerList,
        identifier: 'id',
        patch: { id, ...updatedFields },
      })
    },
    async sendPlayerAction (actionName, actionData) {
      try {
        await Repositories.multitaskPlayer.sendAction({
          gameId: this.game.id,
          playerId: this.currentPlayerId,
          actionName,
          actionData,
        })
        await this.fetchData()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
        console.error(error)
      }
    },
  },
  provide () {
    return {
      sendPlayerAction: this.sendPlayerAction,
    }
  },
  expectedRoutesForStatus: {
    [AVAILABLE_STATUS.ready]: 'MultiTaskWelcome',
    [AVAILABLE_STATUS.playing]: 'MultiTaskPlaying',
    [AVAILABLE_STATUS.finished]: 'MultiTaskFinished',
  },
}
</script>
